import React from 'react'
import { format, distanceInWords, differenceInDays } from 'date-fns'

import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

import BlockContent from './block-content'

const ParagraphClass = 'dib relative w-100 measure-wide dark-gray fw4 mb4'
const HeadingClass = 'dib relative w-100 measure f2 fw5 mv3 black'

function Work(props) {
  const { _rawBody2, _rawBody, title, publishedAt, excerpt, mainImage } = props

  return (
    <article>
      <section className="dib relative w-100 bg-white lh-copy dark-gray f5">
        <div className="db center mw8 ph4 pv6">
          <div className="row">
            <div className="col-xs-12 col-md-7">
              <div className="dib relative w-100 pr0 pr4-l">
                {mainImage && mainImage.asset && mainImage.asset._id ? (
                  <div
                    className="dib dn-l relative w-100 fit br3 lawyer-pic"
                    style={{
                      background: `url(${imageUrlFor(
                        buildImageObj(mainImage)
                      ).url()}) no-repeat center center / cover`
                    }}
                  >
                    &nbsp;
                  </div>
                ) : null}
                <h1 className={HeadingClass}>{title}</h1>
                {excerpt ? <p className={ParagraphClass}>{excerpt}</p> : null}
                {publishedAt ? (
                  <small className="dib relative w-100 f6 gray">
                    Published:{' '}
                    {differenceInDays(new Date(publishedAt), new Date()) > 3
                      ? distanceInWords(new Date(publishedAt), new Date())
                      : format(new Date(publishedAt), 'MMMM Do YYYY')}
                  </small>
                ) : null}
                {_rawBody ? <BlockContent blocks={_rawBody} /> : null}
              </div>
            </div>
            <div className="col-xs-12 col-md-5">
              {mainImage && mainImage.asset && mainImage.asset._id ? (
                <div
                  className="dn dib-l relative w-100 fit br3 lawyer-pic"
                  style={{
                    background: `url(${imageUrlFor(
                      buildImageObj(mainImage)
                    ).url()}) no-repeat center center / cover`
                  }}
                >
                  &nbsp;
                </div>
              ) : null}
              {_rawBody2 ? <BlockContent blocks={_rawBody2} /> : null}
            </div>
          </div>
        </div>
      </section>
    </article>
  )
}

export default Work
