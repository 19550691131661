import React from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import Project from '../components/work'
import SEO from '../components/seo'
import Layout from '../containers/layout'

export const query = graphql`
  query InsightTemplateQuery($id: String!) {
    insight: sanityInsight(id: { eq: $id }) {
      id
      publishedAt
      mainImage {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
        alt
      }
      title
      slug {
        current
      }
      _rawBody
    }
  }
`

const InsightTemplate = props => {
  const { data, errors } = props
  const insight = data && data.insight
  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {insight && <SEO title={insight.title || 'Untitled'} />}

      {errors && <GraphQLErrorList errors={errors} />}
      {insight && <Project {...insight} />}
    </Layout>
  )
}

export default InsightTemplate
